import {useEffect, useState} from "react";
import logo from "./resources/Spaider-icon-black-RGB.png";
import {useSocket} from "./WebSocketHelper";


export default function LoginPage(){
    //const auth = { password: "123456", email: "admin@spaider.com" };
    const [input, setInput] = useState({username: "", password: ""});
    const [error, setError] = useState("");

    const { socketCall, socketListen } = useSocket();

    useEffect(() => {
        socketListen('login')
    },[]);

    const[message, setMessage] = useState('')
    const[accessToken, setAccesToken] = useState('')

    useEffect(() => {
        console.log(message +" : "+accessToken)
    }, [message]);

    const handleClick = () => {
        socketCall('login', input)
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setInput({ ...input, [name]: value });
        error!==""&&setError("")
    }



    return(
        <div className={"LoginPage"}>
            <img src={logo} width="70%" style={{margin: "auto"}}/>
            <input placeholder="användarnamn" name="username" value={input.username} onChange={handleChange}/>
            <input placeholder="lösenord" name="password" value={input.password} onChange={handleChange} type="password"
                   onKeyPress={(event) => {
                       if (event.key === 'Enter') {
                           handleClick()
                       }
                   }}/>
            <button onClick={handleClick}>Logga in</button>
            <p style={{minHeight: "1.4em", color: "red"}}>{error}</p>
        </div>
    )
}

