import {BlockDiv, FlexDiv} from "../../tiny/FlexDiv";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPowerOff, faRefresh} from "@fortawesome/free-solid-svg-icons";

export default function Home(){
    const Server = JSON.parse(sessionStorage.getItem('server'))
    const running = Server.Running
    const history = 4;
    const getYesterdayDate = () => {
        const yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);
        return yesterday.toISOString().slice(0, 10);
    };

    return(
        <FlexDiv column={true}>
            <FlexDiv style={{ width: "90%", maxWidth: "900px", marginTop: "20px", borderBottom: `15px solid ${running ? "Red" : "Green"}`}} column={true} dark={true}>
                <h1>{Server.Name}</h1>
                <p>{Server.IP}</p>
            </FlexDiv>
            <FlexDiv>
                <FlexDiv className="Server Block" style={{border: "none"}}>
                    <FontAwesomeIcon icon={faPowerOff}/>
                    <h3>{running?'Starta':'Stoppa'}</h3>
                </FlexDiv>
                <FlexDiv className="Server Block" style={{border: "none"}}>
                    <FontAwesomeIcon icon={faRefresh}/>
                    <h3>Restart</h3>
                </FlexDiv>
            </FlexDiv>
            <div className="BlockContainer">
                <div className="Block b1">
                    <FlexDiv>
                        <h4>Minne</h4>
                        <h4>28%</h4>
                    </FlexDiv>
                    <progress value={0.28}/>
                    <FlexDiv>
                        <h5>3.36 GB av 12 GB</h5>
                        <h5>+ 2.1%</h5>
                    </FlexDiv>
                </div>
                <div className="Block b2">
                    <FlexDiv>
                        <h4>Traffik</h4>
                        <h4>4%</h4>
                    </FlexDiv>
                    <progress value={0.04}/>
                    <FlexDiv>
                        <h5>30MB/s av 1BB/s</h5>
                        <h5>- 1.4%</h5>
                    </FlexDiv>
                </div>
                <div className="Block b3">
                    <FlexDiv>
                        <h4>Lagring</h4>
                        <h4 style={{color: "red"}}>80%</h4>
                    </FlexDiv>
                    <progress value={0.8}/>
                    <FlexDiv>
                        <h5>3.2TB av 4TB</h5>
                        <h5>+0.2%</h5>
                    </FlexDiv>
                </div>
                <div className="Block b4">
                    <h2>Historik</h2>
                    <h4>{new Date().toISOString().slice(0, 10)}</h4>
                    {[...Array(history)].map((_, index) => (
                        <p key={index}>Historik {index + 1}</p>
                    ))}
                    <h4>{getYesterdayDate()}</h4>
                    {[...Array(history)].map((_, index) => (
                        <p key={index}>Historik {index + 5 }</p>
                    ))}
                </div>
            </div>
        </FlexDiv>
)

}
