import React, { useState, useEffect } from 'react';
//import Ably from 'ably';
import { FlexDiv } from "../../tiny/FlexDiv";
import {useSocket} from "../../WebSocketHelper";
import {useMemory} from "../../Context";
/*
const ably = new Ably.Realtime('3QNx4A.ms1udQ:YvrHwIAcgEmYrwafrTehzusoy8INccwSGk9mGphQaZ4');
const commandChannel = ably.channels.get('command');
const resultChannel = ably.channels.get('result');
const restartChannel = ably.channels.get('restart');
*/
export default function ProcessList() {
    const {server} = useMemory();

    // New websocket functions
    const [processes, setProcesses] = useState([]);
    const {socketCall, socketListen} = useSocket()

    useEffect(() => {
        socketListen('exec', [setProcesses], );
        socketCall('exec','pm2 jlist',server.ServerID);
        console.log(server);

    }, []);


    //const [processes, setProcesses] = useState([]);
    const [selectedActions, setSelectedActions] = useState({});
/*
    useEffect(() => {
        resultChannel.subscribe('result', (msg) => {
            setProcesses(msg.data);
        });
        restartChannel.subscribe('restart', (msg) => {
            alert(msg.data.message);
            handleList();
        });

        return () => {
            resultChannel.unsubscribe();
            restartChannel.unsubscribe();
        };
    }, []);
*/
    useEffect(() => {
        //handleList()
    }, []);

    const handleList = () => {
        // socketListen('pm2.list', [setProcesses])
        //socketCall('pm2.list')
    };

    const handleAction = (action, id) => {
        switch (action) {
            case "Restart":
                //commandChannel.publish('command', `restart ${id}`);
                break;
            case "Stop":
                //commandChannel.publish('command', `stop ${id}`);
                break;
            case "Start":
                //commandChannel.publish('command', `start ${id}`);
                break;
            default:
                console.log("No action found");
        }
    };

    const handleSelectChange = (e, id) => {
        const action = e.target.value;
        setSelectedActions(prev => ({ ...prev, [id]: action }));
        handleAction(action, id);
        setSelectedActions({});
    };
    // Function to convert memory to human-readable format
    function formatMemory(bytes) {
        const sizes = ['B', 'KB', 'MB', 'GB', 'TB'];
        if (bytes === 0) return '0 B';
        const i = Math.floor(Math.log(bytes) / Math.log(1024));
        return parseFloat((bytes / Math.pow(1024, i)).toFixed(2)) + ' ' + sizes[i];
    }
    // Function to convert uptime to human-readable format
    function formatUptime(startTime, currentStatus) {
        if(currentStatus==="stopped")
        {
            return "-";
        }
        const currentTime = Date.now();
        const duration = currentTime - startTime;

        const minutes = Math.floor(duration / (1000 * 60));
        const hours = Math.floor(duration / (1000 * 60 * 60));
        const days = Math.floor(duration / (1000 * 60 * 60 * 24));

        if (days > 0) {
            return `${days} day${days > 1 ? 's' : ''}`;
        } else if (hours > 0) {
            return `${hours} hour${hours > 1 ? 's' : ''}`;
        } else {
            return `${minutes} minute${minutes > 1 ? 's' : ''}`;
        }
    }




    return (
        <FlexDiv column={true}>
            <h1>Serverprocesser</h1>
            <table>
                <thead style={{backgroundColor: "var(--block)", borderBottom: "1px solid var(--foreground-color)"}}>
                <tr>
                    <th style={{ width: "2%" }}>ID</th>
                    <th style={{ width: "auto" }}>Namn</th>
                    <th style={{ width: "8%" }}>PID</th>
                    <th style={{ width: "10%" }}>Upptid</th>
                    <th style={{ width: "8%" }}>Status</th>
                    <th style={{ width: "10%" }}>Minne</th>
                    <th style={{ width: "8%" }}>Handling</th>
                </tr>
                </thead>
                <tbody>
                {processes.map((proc) => (
                    <tr key={proc.id}>
                        <td>{proc.id}</td>
                        <td>{proc.name}</td>
                        <td>{proc.pid}</td>
                        <td>{formatUptime(proc.pm2_env.pm_uptime, proc.pm2_env.status)}</td>
                        <td>{proc.pm2_env.status}</td>
                        <td>{formatMemory(proc.monit.memory)}</td>
                        <td>
                            <select
                                value={selectedActions[proc.id] || ""}
                                onChange={(e) => handleSelectChange(e, proc.id)}
                                style={{padding: "3px"}}
                            >
                                <option value="">---------</option>
                                <option value="Restart">Restart</option>
                                <option value="Stop">Stop</option>
                                <option value="Start">Start</option>
                            </select>
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>
        </FlexDiv>
    );
}
