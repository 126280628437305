class WebSocketService {
    constructor() {
        this.socket = null;
        this.messageQueue = [];
        this.callbacks = {};
        this.reconnectAttempts = 0;
        this.maxReconnectAttempts = 10;
        this.reconnectDelay = 1000; // Initial reconnection delay in milliseconds

        this.initializeWebSocket();

        // Listen to page visibility changes (for tab switching, minimizing)
        document.addEventListener('visibilitychange', this.handleVisibilityChange);
    }

    initializeWebSocket() {
        this.socket = new WebSocket('wss://s1.redata.se/ws1');
        this.socket.onopen = () => {
            console.log('WebSocket connected');
            this.reconnectAttempts = 0; // Reset reconnect attempts
            this.flushMessageQueue();
        };

        this.socket.onmessage = (message) => {
            this.handleMessage(message);
        };

        this.socket.onclose = () => {
            console.log('WebSocket disconnected');
            this.attemptReconnect();
        };

        this.socket.onerror = (error) => {
            console.error('WebSocket error:', error);
            this.attemptReconnect();
        };
    }

    attemptReconnect() {
        if (this.reconnectAttempts < this.maxReconnectAttempts) {
            const delay = this.reconnectDelay * Math.pow(2, this.reconnectAttempts);
            console.log(`Attempting reconnect in ${delay}ms`);

            setTimeout(() => {
                this.reconnectAttempts += 1;
                this.initializeWebSocket();
            }, delay);
        } else {
            console.error('Max reconnect attempts reached');
        }
    }

    sendMessage(message) {
        if (this.socket.readyState === WebSocket.OPEN) {
            this.socket.send(JSON.stringify(message));
            console.log('Message sent:', message);
        } else {
            this.messageQueue.push(message);
            console.log('Message queued:', message);
        }
    }

    flushMessageQueue() {
        while (this.messageQueue.length > 0) {
            const message = this.messageQueue.shift();
            this.socket.send(JSON.stringify(message));
            console.log('Message sent from queue:', message);
        }
    }

    handleMessage(message) {
        try {
            const data = JSON.parse(message.data);
            console.log('Message received:', data);
            const { command } = data;

            if (this.callbacks[command]) {
                this.callbacks[command](data);
            } else {
                console.log(`No callback registered for command: ${command}`);
            }
        } catch (error) {
            console.error('Error handling message:', error);
        }
    }

    registerCallback(command, callback) {
        this.callbacks[command] = callback;
        console.log(`Callback registered for command: ${command}`);
    }

    handleVisibilityChange = () => {
        if (document.visibilityState === 'visible' && this.socket.readyState !== WebSocket.OPEN) {
            console.log('Page is visible again, attempting to reconnect');
            this.attemptReconnect();
        }
    }

    // Clean up when the service is no longer needed
    cleanup() {
        if (this.socket) {
            this.socket.close();
        }
        document.removeEventListener('visibilitychange', this.handleVisibilityChange);
    }
}

export default new WebSocketService();
