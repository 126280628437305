export function FlexDiv(props) {
    return (
        <div
            style={{
                ...props.style,
                display: "flex",
                alignItems: "center",
                justifyContent:"space-between",
                flexDirection: props.column ? 'column' : 'row',
                margin: props.column && "inherit auto",
                backgroundColor: props.dark && "var(--accent-color)",
                color: props.dark && "var(--block)",

            }}
            className={props.className}
            onClick={props.onClick}
        >
            {props.children}
        </div>
    );
}

export function BlockDiv(props){
    return (
        <div
            style={{
                ...props.style,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                padding: "10px",
                margin: "inherit auto",
                border: "none"

            }}
            className="ServerBlock"
            onClick={props.onClick}
        >
            {props.children}
        </div>
    )
}
