import React from 'react';
import './App.css';
import LoginPage from "./LoginPage";
import MainPage from "./MainPage";
import { AuthProvider, useMemory } from './Context';

function AppContent() {
  const { auth } = useMemory();
  return auth ? (
        <MainPage />
  ) : (
      <LoginPage />
  );
}

function App() {
  return (
      <AuthProvider>
        <AppContent />
      </AuthProvider>
  );
}

export default App;
