import {FlexDiv} from "../../tiny/FlexDiv";
import servers from '../../JSON/servers.json'

export default function ClientHome(){
    const {Name, ServerID, Contact, Address, Phone} = JSON.parse(sessionStorage.getItem('client'));
    const server = ServerID
    return(
        <FlexDiv column={true}>
            <FlexDiv style={{width: "70%", marginTop: "70px"}} column={true} dark={true}>
                <h1>{Name}</h1>
                <h3>{`Server: ${server}`}</h3>
            </FlexDiv>
            <div style={{width: "60%", display: "flex", justifyContent: "space-between", alignItems: "flex-start", flexWrap: "wrap"}}>
                <FlexDiv column={true} style={{width: "65%", margin: 0}}>
                    <FlexDiv className={"Block"} style={{width: "100%"}}>
                        <h3>Tjänst</h3>
                        <h3>Enheter</h3>
                        <h3>Pris</h3>
                    </FlexDiv>
                    <div className={"Block"} style={{width: "100%"}}>
                        <FlexDiv>
                            <h4>Svepos</h4>
                            <p>10st</p>
                            <p>1200kr</p>
                        </FlexDiv>
                        <FlexDiv>
                            <h4>Svepos</h4>
                            <p>10st</p>
                            <p>1200kr</p>
                        </FlexDiv>
                        <FlexDiv>
                            <h4>Svepos</h4>
                            <p>10st</p>
                            <p>1200kr</p>
                        </FlexDiv>
                        <FlexDiv>
                            <h4>Svepos</h4>
                            <p>10st</p>
                            <p>1200kr</p>
                        </FlexDiv>
                    </div>
                    <FlexDiv className={"Block"} style={{width: "100%"}}>
                        <h4>Total</h4>
                        <h4>$$$ kr</h4>
                    </FlexDiv>
                </FlexDiv>
                <div className={"Block"} style={{width: "auto", height: "100%"}}>
                    <h3>Kontaktuppgifter</h3>
                    <p>{`Epost: ${Contact}`}</p>
                    <p>{`Address: ${Address}`}</p>
                    <p>{`Telefon: ${Phone}`}</p>
                </div>
            </div>
        </FlexDiv>
    )
}
