import { FlexDiv } from "../../tiny/FlexDiv";
import {useEffect, useState} from "react";

export default function Console() {
    const [lines, setLines] = useState([]);
    const [inputValue, setInputValue] = useState('');

    function handleEnter(e) {
        setLines(prevCommands => [...prevCommands, inputValue]);
        setInputValue('');  // Clear the input field after adding the command
    }

    useEffect(() => {
        if (lines.length > 0 && lines[lines.length - 1] === 'console.log("Hello World")') {
            setLines(prevLines => [...prevLines, "> Hello World"]);
        }
    }, [lines]);

    return (
        <FlexDiv column={true}>
            <div className="console">
                <p className="console">Microsoft Windows [Version 10.0.22631.3737]</p>
                <p className="console">(C) Microsoft Corporation. All rights reserved.</p>
                {lines.map((command, index) => (
                    <p style={{paddingTop: "15px"}} key={index} className="console">{command}</p>
                ))}
            </div>
            <div style={{position: "absolute", bottom: 0, width: "100%", textAlign: "center"}} >
                <input
                    style={{width: "400px"}}

                    placeholder=">"
                    className="console"
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                    onKeyPress={event => {
                        if (event.key === 'Enter') {
                            event.preventDefault();
                            handleEnter();
                        }
                    }}
                />
                </div>
        </FlexDiv>
    );
}
