import { useMemory } from './Context';
import WebSocketService from './WebSocketService';

export function useSocket() {
    const { token, login } = useMemory();

    const socketCall = (command, body, childServerId) => {
        const message = { command, token, args: body, childServerId };
        console.log('Sending message:', message);
        WebSocketService.sendMessage(message);
    };

    const socketListen = (command, methods) => {
        WebSocketService.registerCallback(command, (result) => {
            console.log('Received result for command:', command, result);
            if (result && result.error) {
                console.log(`Error: ${result.error}`);
            } else {
                handleSuccess(result);
            }
        });

        function handleSuccess(result) {
            switch (command) {
                case 'login':
                    login(result.accesstoken);
                    break;
                case 'list.servers':
                    methods[0](result.data)
                    methods[1](result.data)
                    methods[2](result.extraData)
                    break;
                case 'exec':
                    console.log(result.output);
                    methods[0](JSON.parse( result.output));

                    break;
                default:
                    if (methods && methods[0]) {
                        methods[0](result.data);
                    } else {
                        console.log('No method provided to handle success for command:', command);
                    }
            }
        }
    };

    return { socketCall, socketListen };
}
