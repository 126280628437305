import {FlexDiv} from "../../tiny/FlexDiv";

export default function Logs(){
    return(
        <FlexDiv column={true}>

        </FlexDiv>
    )
}

function expandableRow(){

}
