import React, {createContext, useState, useContext, useEffect} from 'react';

const Context = createContext();

export function AuthProvider({ children }) {
    const [auth, setAuth] = useState(JSON.parse(sessionStorage.getItem('auth')) || false);
    const [token, setToken] = useState(JSON.parse(sessionStorage.getItem('token')) || null);
    const [server, setServer] = useState(JSON.parse(sessionStorage.getItem('server')) || null);
    const [client, setClient] = useState(JSON.parse(sessionStorage.getItem('client')) || null);
    const [mobile, setMobile] = useState(window.matchMedia("(orientation: portrait)").matches);

    const login = (token) => {
        console.log(token);
        setAuth(true);
        setToken(token);
        sessionStorage.setItem('auth', JSON.stringify(true));
        sessionStorage.setItem('token', JSON.stringify(token));
    };

    const logout = () => {
        setAuth(false);
        setToken(null);
        sessionStorage.clear();
    };

    const selectClient = (client) => {
        if (auth) {
            setClient(client);
            sessionStorage.setItem('client', JSON.stringify(client));
        } else {
            console.log('Not authorised');
        }
    };

    const selectServer = (server) => {
        if (auth) {
            setServer(server);
            sessionStorage.setItem('server', JSON.stringify(server));
        } else {
            console.log('Not authorised');
        }
    };

    const removeSelect = () => {
        sessionStorage.removeItem('server');
        sessionStorage.removeItem('client');
        setServer(null);
        setClient(null);
    };

    return (
        <Context.Provider value={{ auth, token, server, client, login, logout, selectClient, selectServer, removeSelect, mobile, setMobile }}>
            {children}
        </Context.Provider>
    );
}

export function useMemory() {
    return useContext(Context);
}
