import React, {useState} from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faBoxOpen,
    faCashRegister, faChartLine,
    faChevronLeft,
    faFileLines, faGears, faMoneyBill,
    faPowerOff, faReceipt,
    faServer,
    faShapes, faSquare,
    faTerminal,
    faUsers, faUtensils, faWarehouse,
    faWrench
} from '@fortawesome/free-solid-svg-icons';
import Home from './Nav/ServerPages/Home';
import Processes from "./Nav/ServerPages/Processes";
import { FlexDiv } from "./tiny/FlexDiv";
import Console from "./Nav/ServerPages/Console";
import Logs from "./Nav/ServerPages/Logs";
import ServerSettings from "./Nav/ServerPages/ServerSettings";import Clients from "./Nav/ServerPages/Clients";
import {faCircleUser} from "@fortawesome/free-solid-svg-icons/faCircleUser";
import ClientHome from "./Nav/ClientPages/ClientHome";
import ClientProcesses from "./Nav/ClientPages/ClientProcesses";
import HardWare from "./Nav/ClientPages/Hardware"
import ClientLogs from "./Nav/ClientPages/ClientLogs";
import Repports from "./Nav/ClientPages/Repports";
import Tables from "./Nav/ClientPages/Tables";
import ClientConfig from "./Nav/ClientPages/ClientConfig";
import {useMemory} from "./Context";



export default function Nav({ active, setActive, toggle}) {
    const {server, client, removeSelect} = useMemory()

    function renderNav(){
        if(server){
            return ServerStack()

        }else if(client){
            return ClientStack()
        }else{
            return ErrorStack()
        }
    }
    // NavItem component definition
    const NavItem = ({ page, icon: IconComponent, title }) => {
        return (
            <div className="NavIcon" onClick={() => {
                setActive(page)
                toggle()
            }} style={{ backgroundColor: active.type === page.type && 'var(--accent-dark)'}}>
                <FontAwesomeIcon icon={IconComponent} style={{color: "var(--block)", width: "20px"}} />
                <p style={{color: "var(--block)"}}>{title}</p>
            </div>
        );
    };

    function ServerStack() {
        return <>
            <div>
                <div className="Server Block" style={{borderColor: server.Running ? "Red" : "Green"}}>
                    <FlexDiv>
                        <h4 style={{minWidth: "80px"}}>{server.Name}</h4>
                        <FontAwesomeIcon icon={faPowerOff} className="PressIcon"/>
                    </FlexDiv>
                </div>
                <NavItem key="home" page={<Home/>} icon={faServer} title="Server"/>
                <NavItem key="processes" page={<Processes/>} icon={faGears} title="Processer"/>
                <NavItem key="console" page={<Console/>} icon={faTerminal} title="Konsol"/>
                <NavItem key="logs" page={<Logs/>} icon={faFileLines} title="Loggar"/>
                <NavItem key="clients" page={<Clients/>} icon={faUsers} title="Kunder"/>
                <NavItem key="settings" page={<ServerSettings/>} icon={faWrench} title="Config"/>
            </div>
            <FlexDiv className="Block Pressable" onClick={removeSelect}>
                <FontAwesomeIcon icon={faChevronLeft}/>
                <h4>Byt Server</h4>
            </FlexDiv>
        </>
    }

    function ClientStack() {
        return <>
            <div>
                <h4 className="Block" style={{
                    padding: "5px",
                    textAlign: "center"
                }}> {client.Name}</h4>
                <NavItem key="home" page={<ClientHome/>} icon={faCircleUser} title="Kundbild"/>
                <NavItem key="hardware" page={<HardWare/>} icon={faCashRegister} title="Hårdvara"/>
                <NavItem key="logs" page={<ClientLogs/>} icon={faFileLines} title="Logfiler"/>
                <NavItem key="processes" page={<ClientProcesses/>} icon={faGears} title="Processer"/>
                <NavItem key="reports" page={<Repports/>} icon={faChartLine} title="Rapporter"/>
                <NavItem key="tables" page={<Tables/>} icon={faUtensils} title="Bord"/>
                <NavItem key="tabs" page={<Tables/>} icon={faReceipt} title="Notor"/>
                <NavItem key="products" page={<ClientConfig/>} icon={faBoxOpen } title="Produkter"/>
            </div>
            <FlexDiv className="Block Pressable" onClick={removeSelect}>
                <FontAwesomeIcon icon={faChevronLeft}/>
                <h4>Byt kund</h4>
            </FlexDiv>
        </>;
    }

    function ErrorStack() {
        return (<div>
            <FlexDiv className="Block Pressable" onClick={removeSelect}>
                <FontAwesomeIcon icon={faChevronLeft}/>
                <h4>Tillbaka</h4>
            </FlexDiv>
        </div>)
    }

    return (
        <FlexDiv column={true} className="Nav">
            {active?renderNav():ErrorStack()}
        </FlexDiv>
    );
}
