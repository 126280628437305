import {FlexDiv} from "./tiny/FlexDiv";
import fullLogo from './resources/Spaider-icon-black-RGB.png'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowRightFromBracket, faBars} from "@fortawesome/free-solid-svg-icons";
import React, {useEffect} from "react";
import {useMemory} from "./Context";

export default function Header(props){
    const {logout, server, client, mobile} = useMemory()
    useEffect(() => {
        console.log(server+" + "+client+" = "+(server||client))
    }, [server]);

    return(
        <FlexDiv className="Header">
            <FontAwesomeIcon icon={faBars} size={"xl"} style={{display: `${(server||client)&&mobile?"block":"none"}`}} onClick={props.expand}/>
            <img src={fullLogo} style={{height: "50px"}}/>
            <FlexDiv className="Block Pressable" onClick={logout} style={{padding: "2px"}}>
                <FontAwesomeIcon icon={faArrowRightFromBracket} />
                <p>Logga ut</p>
            </FlexDiv>
        </FlexDiv>
    )
}
