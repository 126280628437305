import React, { useEffect, useState, useCallback } from "react";
import Nav from "./Nav";
import Home from "./Nav/ServerPages/Home";
import Header from "./Header";
import servers from './JSON/servers.json';
import ClientHome from "./Nav/ClientPages/ClientHome";
import {useSocket} from "./WebSocketHelper";
import {useMemory} from "./Context";




function Server({ server, running}) {
    const {selectServer} = useMemory()
    const id  = server.ServerID;
    const active = running.includes(id.toString())
    return (
        <div
            className="Server Block"
            key={id}
            style={{
                borderLeft: `10px solid ${active? 'green' : 'red'}`,
                padding: '10px',
                margin: '10px',
                borderRadius: '5px',
                width: '160px'
            }}
            onClick={() => selectServer(server)}
        >
            <h3>{server.Name}</h3>
            <h6>Senast aktiv:</h6>
            <h5>{new Date(server.LastActive).toLocaleString()}</h5>
        </div>
    );
}

const Search = React.memo(({ search, setSearch }) => {
    const handleChange = (e) => setSearch(e.target.value);

    return (
        <div style={{textAlign: "center"}}>
        <input
            key="serverSearch"
            type="text"
            value={search}
            onChange={handleChange}
            placeholder={"Sök server..."}
        />
        </div>
    );
});

function Selection() {
    const [list, setList] = useState([]);
    const [filtered, setFiltered] = useState([]);
    const [serverSearch, setServerSearch] = useState("");
    const [running, setRunning] = useState([])

    useEffect(() => {
        console.log(running)
    }, [running]);

    const {socketCall, socketListen} = useSocket()

    useEffect(() => {
        socketListen('list.servers', [setList, setFiltered, setRunning])
        socketCall('list.servers')
    }, []);

    useEffect(() => {
        if (serverSearch.length > 0) {
            const filteredList = list.filter((item) =>(
                item.Name.toLowerCase().includes(serverSearch.toLowerCase())
                || item.ServerID.toString() === serverSearch)
            );
            setFiltered(filteredList);
        } else {
            setFiltered(list);
        }
    }, [serverSearch, list]);


    return (
        <div style={{ paddingTop: '10vh'}}>
            <CustomerSearch/>
            <h1 style={{ textAlign: 'center' }}>Servers</h1>
            <Search search={serverSearch} setSearch={setServerSearch} />
            <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: '10px 3vw' }}>
                {filtered.map(s => (
                    <Server server={s} running={running}/>
                ))}
            </div>
        </div>
    );
}

function CustomerSearch() {
    const [search, setSearch] = useState("");
    const [list, setList] = useState([])
    const [results, setResults] = useState([]);

    const handleChange = (e) => setSearch(e.target.value);

    const {socketListen, socketCall} = useSocket()
    const {selectClient} = useMemory()

    useEffect(() => {
        socketCall('list.customers')
            socketListen('list.customers', [setList])
    }, []);
    useEffect(() => {
        if (search.length > 0) {
            const searchLower = search.toLowerCase();
            const clientsArray = (list);
            const filteredResults = clientsArray.filter(client =>
                client.Name.toLowerCase().includes(searchLower) || client.id === search
            );
            setResults(filteredResults);
        } else {
            setResults([]);
        }
    }, [search]);

    return (
        <div style={{position: 'relative', marginBottom: "50px", textAlign: "center" }}>
            <input
                placeholder="Sök kund..."
                value={search}
                type="search"
                onChange={handleChange}
                style={{ width: '50%', margin: "auto", minWidth: "300px" }}
                onKeyPress={(event) => {
                    if (event.key === "Enter" && results.length === 1) {
                        //handleClientChoice(results[0]);
                        selectClient(results[0])
                    }
                }}
            />
            {results.length > 0 && (
                <div
                    style={{
                        position: 'absolute',
                        top: '90%',
                        left: 0,
                        right: 0,
                        border: '1px solid var(--background-color)',
                        backgroundColor: 'var(--block)',
                        zIndex: 1000,
                        maxHeight: '200px',
                        overflowY: 'auto',
                    }}
                >
                    {results.map((item) => (
                        <div
                            className="Pressable"
                            key={item.CustomerID}
                            style={{ padding: '8px', cursor: 'pointer', margin: 0 }}
                            onClick={() => {selectClient(item); }}
                        >
                            <h4 className="Pressable" style={{ margin: 0 }}>{item.Name}</h4>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
}

export default function MainPage() {
    const {server, client, mobile} = useMemory()

    const getInitial = () => {
        if (server) {
            return <Home />;
        } else if (client) {
            return <ClientHome />;
        } else {
            return null;
        }
    };
    const [active, setActive] = useState(getInitial);
    const [navExpanded, setNavExpaned] = useState(!mobile);

    useEffect(() => {
        setActive(getInitial);
    }, [server, client]);

    const expandNav = () => {
        if(mobile){
            setNavExpaned(!navExpanded)
        }
    }


    return(
        <>
            <Header expand={expandNav}/>
            {
                (client||server)?
                    <>
                    {navExpanded&&<Nav active={active} setActive={setActive} toggle={expandNav}/>}
                        <div style={{ marginTop: "60px", overflowY: "scroll", marginLeft: `${!mobile&&'178px'}` }}>
                            {active}
                        </div>
                    </>:
                    <Selection/>
            }
        </>
    )
}
